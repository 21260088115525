import { NextSeo } from 'next-seo';
import 'styles/fonts.css';
import 'styles/global.css';
import 'styles/reset.css';
import 'windi.css';

import { Container, ScrollArea } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RoleGuard } from 'components/Guard/RoleGuard';
import { UserRoleModal } from 'components/Modals/UserRoleModal/UserRoleModal';
import { DatabaseContextProvider } from 'context/database';
import { CustomMantineProvider } from 'context/mantine';
import Inspect from 'inspx';
import config from 'lib/config';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { Database } from 'old/library/services/db-parser/db-parser.types';
import React, { useState } from 'react';
import { GoogleAnalytics, usePageViews } from 'nextjs-google-analytics';
import UiProvider from 'context/uiContext';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err: any) => {
      showNotification({
        title: 'Something went wrong',
        message: err?.message || 'Try again later',
        color: 'red',
        autoClose: 6000,
      });
    },
  }),
});

const App = ({
  Component,
  pageProps: { session, needsDatabase, ...pageProps },
}: AppProps) => {
  usePageViews();
  const [database, setDatabase] = useState<null | Database>(null);

  // @ts-ignore
  const getLayout = Component.getLayout || ((page) => page);

  const plausibleUrl = `https://${config.plausible.domain}`;

  return (
    <>
      <Script
        defer
        data-domain={config.plausible.domain}
        src="https://stats.qwiz.party/js/app.js"
        strategy="afterInteractive"
      />
      <NextSeo
        title="Kindle Vocabulary Builder"
        description="App for building pub quizzes"
        openGraph={{
          url: plausibleUrl,
          title: 'Kindle Vocabulary Builder',
          description: 'App for building pub quizzes',
          images: [
            {
              url: `${plausibleUrl}/assets/images/cover.png`,
              width: 1423,
              height: 800,
              alt: 'Cover logo',
              type: 'image/png',
            },
          ],
          site_name: 'Kindle Vocabulary Builder',
        }}
      />
      <GoogleAnalytics />
      {/* <SessionProvider session={session}> */}
      <QueryClientProvider client={queryClient}>
        <DatabaseContextProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <UiProvider>
            <CustomMantineProvider>
              <Inspect>
                {/* <RoleGuard> */}
                <Container
                  fluid
                  px={0}
                  sx={(t) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    color:
                      t.colorScheme === 'dark'
                        ? t.colors.gray[4]
                        : t.colors.gray[9],
                  })}
                >
                  {/* <ScrollArea sx={{ flex: 1 }}> */}
                  {getLayout(
                    <Component
                      {...pageProps}
                      setDatabase={setDatabase}
                      database={database}
                    />
                  )}
                  {/* <UserRoleModal /> */}
                  {/* </ScrollArea> */}
                </Container>
                {/* </RoleGuard> */}
              </Inspect>
            </CustomMantineProvider>
          </UiProvider>
        </DatabaseContextProvider>
      </QueryClientProvider>
      {/* </SessionProvider> */}
    </>
  );
};

export default App;
