import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

export enum TabNames {
  BOOKS = 'BOOKS',
  WORDS = 'WORDS',
}
const initialState: UiContextState = {
  tabName: TabNames.BOOKS,
};

interface UiContextState {
  tabName: TabNames;
}

interface UiContextProps {
  state: UiContextState;
  setState: Dispatch<SetStateAction<UiContextState>>;
}

export const UiContext = createContext<UiContextProps>({
  state: initialState,
  setState: () => {},
});

interface Props {
  children?: ReactNode;
}

const UiProvider: FC<Props> = ({ children }) => {
  const [state, setState] = useState(initialState);

  const value = useMemo(() => {
    return { state, setState };
  }, [state, setState]);

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
};

export default UiProvider;
