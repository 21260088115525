import { Database } from 'old/library/services/db-parser/db-parser.types';
import { AppProps } from '../_app.types';
import { createContext, useState } from 'react';

const initialValue: AppProps = {
  data: null,
  // @ts-ignore
  setDatabase: null,
};

export const DatabaseContext = createContext(initialValue);

// @ts-ignore
export const DatabaseContextProvider = ({ children }) => {
  const [data, setDatabase] = useState<null | Database>(null);

  return (
    <DatabaseContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        data,
        setDatabase,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};
